import React from 'react'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from "gatsby"

const Footer = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allDataJson {
          nodes {
            menu_footer {
              title
              link {
                url
                text
              }
            }
          }
        }
      }
    `
  )

  return (
    <footer className='container font-light text-xs text-xs'>
      <div className='flex justify-center py-10'>
        <div className='w-1/6'>
          Newsletter
        </div>
        {data.allDataJson.nodes[0].menu_footer.map((group, index) => (
          <div key={index} className='w-1/6'>
            <strong className='block mb-2 font-bold text-sm'>{group.title}</strong>
            <ul>
              {group.link.map((item, index) => (
                <li key={index} className='mt-1'><Link to={item.url} className="hover:text-blue-500 hover:underline">{item.text}</Link></li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className='text-gray-500 text-center border-solid border-t border-gray-300 py-5'>
        Toate drepturile rezervate © {new Date().getFullYear()} BaileFiga.com<br></br>
        Acest site si domeniu sunt detinute si operate privat si nu sunt afiliate la niciun guvern sau autoritate municipala.
      </div>
    </footer>
  )
}

export default Footer