import React from 'react'
import { Link } from 'gatsby'

const menuItems = [
  {
    text: 'Hotels',
    url: '/cazare'
  },
  {
    text: 'Flights',
    url: '/flights'
  },
  {
    text: 'Trains',
    url: '/trains'
  },
  {
    text: 'Cars',
    url: '/cars'
  },
  {
    text: 'Tours & Tickets',
    url: '/tours'
  },
  {
    text: 'Attractions',
    url: '/attractions'
  },
  {
    text: 'Trip Coins',
    url: '/trip'
  },
  {
    text: 'Deals',
    url: '/deals'
  }
]

const Menu = () => {
  return (
    <nav className='hidden md:block'>
      <ul className='container flex text-lg'>
        {menuItems.map((item, index) => (
          <li key={index} className='mr-6'>
            <Link to={item.url} className='block border-solid border-b-4 border-transparent py-1 hover:text-blue-500' activeClassName='border-blue-500 text-blue-500'>{item.text}</Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default Menu