import React from 'react'
import { Link } from 'gatsby'
import Icon from './icon';

const menuBottomItems = [
  {
    title: 'Baile Figa',
    icon: 'sunset',
    url: '/'
  },
  {
    title: 'Statiune',
    icon: 'slide',
    url: '/statiune'
  },
  {
    title: 'Cazare',
    icon: 'bed',
    url: '/cazare'
  },
  {
    title: 'Locatie',
    icon: 'location',
    url: '/'
  },
  {
    title: 'Program',
    icon: 'calendar',
    url: '/'
  }
]

const MenuBottom = () => (
  <div className=' md:hidden fixed bottom-0 left-0 w-full bg-white flex text-xs text-gray-700 fill-current'>
    {menuBottomItems.map((item, index) => (
      <div className='w-1/5' key={index}>
        <Link to={item.url} className="block text-center py-2 border-t border-solid border-gray-300 fill-current" activeClassName="text-blue-500 border-blue-500 fill-current">
          <Icon icon={item.icon} className="w-8 mx-auto" />
          <span className="leading-none block mt-1">{item.title}</span>
        </Link>
      </div>
    ))}
  </div>
)

export default MenuBottom;