import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Menu from './menu'

const Header = ({ siteTitle }) => (
  <header className='font-display shadow'>
    <div className='container pt-2 pb-4'>
      <Link to='/' className='text-blue-500 text-2xl font-bold'>BaileFiga<span className='text-yellow-600'>.</span>com</Link>
    </div>
    <Menu />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
