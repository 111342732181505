import React from 'react'

const Hint = () => (
    <div className="fixed top-0 left-0 text-xs text-center">
        <div className="absolute bg-white w-6 block">XS</div>
        <div className="absolute bg-white w-6 hidden sm:block">SM</div>
        <div className="absolute bg-white w-6 hidden md:block">MD</div>
        <div className="absolute bg-white w-6 hidden lg:block">LG</div>
        <div className="absolute bg-white w-6 hidden xl:block">XL</div>
    </div>
)

export default Hint